import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { CSVTools } from '../classes/csv-tools';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class KarmasearchService {

  constructor(public http: HttpClient) { }

  processLog(csvLog, prop) {
    let lastDate = moment.utc(csvLog[0][0]);
    let now = moment.now();
    let result = {
      recent: false,
      'good-karma': 0,
      'bad-karma': 0
    };
    if (lastDate.add(3, 'months').isAfter(now)) {
      prop.recent = 1;
      result.recent = true;
      result['bad-karma'] += 2;
    }
    else {
      prop.recent = 0;
      result['bad-karma'] += 1;
    }
    return result;
  }

  getLog(url, observer, prop) {
    return this.http.get(url, { responseType: 'text' })
      .pipe(
        map((response) => {
          let csvTools = new CSVTools();
          let csvLog = csvTools.parseCSV(response);
          let report = this.processLog(csvLog, prop);
          observer.next(Object.assign(report, { status: "bad-karma", csvLog: csvLog, prop: prop, debug: response }));
          return response;
        }),
        catchError((err) => {
          switch (err.status) {
            case 404:
              observer.next({ status: "no-record", 'good-karma': 0, 'bad-karma': 0, prop: prop, debug: err });
            default:
              observer.next({ status: "api-error", 'good-karma': 0, 'bad-karma': 0, prop: prop, debug: err });
          }
          return err;
        })
      ).subscribe();
  }

  getIPv4Path(url, observer, prop) {
    let _this = this;
    //console.log([this.getIPv4Path, url]);
    return this.http.get(url)
      .pipe(
        map((response: any) => {
          for (let entry of response) {
            if (entry.name == "log.csv") {
              prop["known-offender"]=1;
              //console.log(["test", entry]);
              _this.getLog(entry.download_url, observer, prop);
              //observer.next({ status: "bad-karma", debug: response });
              return response;
            }
          }
          prop["known-offender"]=0;
          observer.next({ status: "expected-log", 'good-karma': 0, 'bad-karma': 0, prop: prop, debug: response });
          return response;
        }),
        catchError((err) => {
          switch (err.status) {
            case 404:
              observer.next({ status: "no-record", 'good-karma': 0, 'bad-karma': 0, prop: prop, debug: err });
            default:
              observer.next({ status: "api-error", 'good-karma': 0, 'bad-karma': 0, prop: prop, debug: err });
          }
          return err;
        })
      ).subscribe();
  }

  getIPv3Path(url, ipParts, prop) {
    let _this = this;
    return new Observable(observer => {
      //console.log([this.getIPv3Path, ipParts, observer]);
      this.http.get(url)
        .pipe(
          map((response: any[]) => {
            if (response.length > 2)
              prop["bad-neighbours"]=1;
            //console.log(["test", response[0]]);
            for (let entry of response) {
              if (entry.name == ipParts[3]) {
                let url = `https://api.github.com/repos/digitalpixies/karmasearch/contents/${ipParts[0]}/${ipParts[1]}/${ipParts[2]}/${ipParts[3]}/log.csv?ref=master`
                _this.getIPv4Path(response[0].url, observer, prop);
                return response;
              }
            }
            if (response.length > 1) {
              observer.next({ status: "no-record", 'good-karma': 0, 'bad-karma': 2, prop: prop });
            }
            else
              observer.next({ status: "no-record", 'good-karma': 1, 'bad-karma': 0, prop: prop });
            return response;
          }),
          catchError((err) => {
            switch (err.status) {
              case 404:
                observer.next({ status: "no-record", 'good-karma': 1, 'bad-karma': 0, prop: prop, debug: err });
              default:
                observer.next({ status: "api-error", 'good-karma': 0, 'bad-karma': 0, prop: prop, debug: err });
            }
            return EMPTY;
          })
        ).subscribe();
    });
  }

  search(ipv4) {
    let ipParts = ipv4.split('.');
    //console.log([this.search, ipv4]);
    let url = `https://api.github.com/repos/digitalpixies/karmasearch/contents/${ipParts[0]}/${ipParts[1]}/${ipParts[2]}`;
    return this.getIPv3Path(url, ipParts, {});
  }

  getExampleIP() {
    let url = `https://api.github.com/repos/digitalpixies/karmasearch/commits/HEAD~100`;
    return this.http.get(url)
    .pipe(
      map((response: any) => {
        let filenameParts = response.files[0].filename.split('/');
        if(filenameParts[4]!="log.csv")
          return "0.0.0.0";
        //console.log([this.getExampleIP, response, filenameParts[4]]);
        return `${filenameParts[0]}.${filenameParts[1]}.${filenameParts[2]}.${filenameParts[3]}`;
      }));
  }
}
