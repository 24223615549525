export class CSVTools {

    constructor() { }

    parseCSV(csvString) {
        let output = [];
        let rows = csvString.replace(/\r/g, '').split('\n');
        for (let row of rows) {
            let outputRow = [];
            let cols = row.split(',');
            for (let col of cols) {
                outputRow.push(col);
            }
            output.push(outputRow);
        }
        return output;
    }
}
