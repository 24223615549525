import { Component, OnInit } from '@angular/core';
import { KarmasearchService } from '../karmasearch/karmasearch.service';

@Component({
  selector: 'ks-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  ipv4 = "";

  result = null;

  constructor(
    private karmasearchService: KarmasearchService) { }

  ngOnInit(): void {
    console.log([window.location]);
    let ip = this.getIPFromURL(window.location.toString());
    if (ip != null)
    {
      this.ipv4 = ip;
      this.search(null);
    }
    else {
      this.karmasearchService.getExampleIP().subscribe((response: any) => {
        this.ipv4 = response;
        //console.log([this.ngOnInit, response]);
      });
    }
  }

  getIPFromURL(url: string) {
    let offset = url.indexOf("/ip/");
    if (offset == -1)
      return null;
    let substring = url.substring(offset);
    let stringParts = substring.split('/');
    return stringParts[2];
  }

  search(clickEvent) {
    this.karmasearchService.search(this.ipv4).subscribe((response: any) => {
      this.result = response.prop;
      this.result.status = "success";
      if (response.prop["recent"])
        this.result.status = "danger";
      else if (response.prop["bad-neighbours"])
        this.result.status = "alert";
      else if (response.prop["known-offender"])
        this.result.status = "alert";
      //console.log(["search", response]);
    });
    if(clickEvent!=null)
      clickEvent.preventDefault();
  }
}
